<script>
    import Base from './Currency.vue';

    export default {
        extends: Base,

        data() {
            return {
                options: {
                    maximumValue: '100.00',
                    minimumValue: '0.00',
                },
            };
        },
    };
</script>
